<template>
  <div>
    <component :is="layout" v-show="$route.name">
      <router-view :key="random"></router-view>
      <template #outside>
        <v-snackbar
          :value="alert.active"
          @input="$store.dispatch('alert/updateActive', $event)"
          :color="alert.type"
          timeout="6000"
        >
          {{ alert.message }}
          <template v-slot:action="{ attrs }">
            <v-btn
              dark
              text
              v-bind="attrs"
              @click.native="$store.dispatch('alert/updateActive', false)"
            >
              {{ $tc('button.ok') }}
            </v-btn>
          </template>
        </v-snackbar>
      </template>
    </component>
    <v-dialog
      v-model="createChannelDialog"
      width="500"
      :key="createChannelKey"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
    >
      <edit-channel
        modal
        @input="createChannelDialog = $event"
        @edited="channelCreated"
      ></edit-channel>
    </v-dialog>
    <v-dialog
      v-model="createPostDialog"
      width="600px"
      :key="'dialog' + createPostKey"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-on:keydown.esc="createPostDialog = false"
      content-class="max-with-viewport"
    >
      <post-maker
        modal
        @input="createPostDialog = $event"
        @edited="postCreated"
        @rerender="createPostKey++"
        :key="createPostKey"
      ></post-maker>
    </v-dialog>
    <v-dialog
      v-if="$store.state.postEditionDialog"
      v-model="$store.state.postEditionDialog"
      persistent
      max-width="500px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <edit-post :post="$store.state.postToEdit"> </edit-post>
    </v-dialog>
    <v-dialog
      v-model="createOrganizationDialog"
      :key="createOrganizationKey"
      width="500"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <edit-organization
        @edited="createOrganizationKey++"
        @input="createOrganizationDialog = $event"
        modal
      ></edit-organization>
    </v-dialog>
    <v-dialog v-model="confirmDialog" width="400">
      <v-card>
        <v-card-title class="headline"
          >{{ $tc('label.confirmation') | capitalize }}
        </v-card-title>
        <v-card-text v-text="confirmMessage"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmCancel">{{ $tc('button.cancel') }}</v-btn>
          <v-btn text color="success" @click="confirmValid"
            >{{ $tc('button.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reportDialog" width="400" :key="reportKey">
      <v-card v-if="reportedPost">
        <v-card-title class="headline primary"
          >{{ $tc('post.report-post') | capitalize }}
        </v-card-title>
        <v-card-text>
          <div @click.capture.stop.prevent>
            <post-item
              v-if="reportedPost && reportedPost.uid"
              :post="reportedPost"
              sub
            ></post-item>
            <div
              v-else
              v-html="reportedPost.textContent"
              class="reported-chat-message"
            ></div>
          </div>
          <textarea-field
            :field="reportForm.reportMessage"
            :label="$tc('post.describe-problem') | capitalize"
            rows="2"
            flat
            auto-grow
            :counter="300"
          ></textarea-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="reportDialog = false"
            >{{ $tc('button.cancel') }}
          </v-btn>
          <v-btn
            text
            color="success"
            @click="submitReport"
            :loading="submitLoading"
            >{{ $tc('button.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="changePaymentCardDialog" max-width="400">
      <v-card>
        <v-card-title class="headline"
          >{{ $tc('label.confirmation') | capitalize }}
        </v-card-title>
        <v-card-text>
          <div
            v-if="$store.state.stripe.stripeElementPaymentError"
            class="error--text my-2"
          >
            {{ $store.state.stripe.stripeElementPaymentError }}
          </div>
          <div class="text-overline" style="font-size: 15px !important;">
            {{ $tc('label.would-change-payment-card') | capitalize }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="$store.dispatch('stripe/setChangePaymentCardDialog', false)"
            >{{ $tc('button.cancel') }}</v-btn
          >
          <v-btn text color="success" @click="confirmCardChange()"
            >{{ $tc('button.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="$store.state.donationDialog"
      :persistent="$vuetify.breakpoint.mdAndUp"
      max-width="500"
    >
      <donation @close="$store.state.donationDialog = false"></donation>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import Vue from 'vue'
import { requestService } from '@/services/request.service.js'
import EditChannel from '@/components/console/EditChannel'
import PostMaker from '@/components/PostMaker'
import EditPost from '@/components/console/EditPost'
import EditOrganization from '@/components/console/EditOrganization'
import TextareaField from '@/components/form/TextareaField'
import PostItem from '@/components/PostItem'
import Validation from '@/components/mixins/Validation'
import firebaseApp from '@/plugins/firebase'
import {
  getRemoteConfig,
  getValue,
  fetchAndActivate,
} from 'firebase/remote-config'
import store from '@/store'
import { createClient } from '@supabase/supabase-js'
import bowser from 'bowser'
import Donation from '@/components/Donation.vue'

const defaultLayout = 'default'

export default Vue.extend({
  name: 'App',
  components: {
    Donation,
    PostItem,
    TextareaField,
    EditOrganization,
    PostMaker,
    EditChannel,
    EditPost,
  },
  mixins: [Validation],
  data: () => ({
    random: 0,
    ui: null,
    checkConnexionTimeout: 0,
    createChannelDialog: false,
    createChannelKey: 0,
    createPostKey: 100,
    createPostDialog: false,
    createOrganizationDialog: false,
    createOrganizationKey: 200,
    confirmDialog: false,
    confirmMessage: '',
    confirmFunction: null,
    confirmCancelFunction: null,
    reportDialog: false,
    reportedPost: null,
    reportForm: {
      reportMessage: {
        value: '',
        required: true,
        minLength: 30,
        maxLength: 300,
      },
    },
    reportKey: 300,
    error: null,
    submitLoading: false,
    donationDialog: false,
  }),
  metaInfo() {
    return {
      title: 'CrowdBunker',
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content: this.$tc('home.freedom-of-expression'),
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content: this.$tc('home.freedom-of-expression'),
        },
        { property: 'og:title', vmid: 'og:title', content: 'CrowdBunker' },
        {
          property: 'twitter:title',
          vmid: 'twitter:title',
          content: 'CrowdBunker',
        },
        {
          property: 'og:image',
          vmid: 'og:image',
          content: 'https://crowdbunker.com/images/og-thumbnail.png',
        },
        {
          property: 'twitter:image',
          vmid: 'twitter:image',
          content: 'https://crowdbunker.com/images/og-thumbnail.png',
        },
        {
          property: 'og:site_name',
          vmid: 'og:site_name',
          content: 'CrowdBunker',
        },
        { property: 'og:type', vmid: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    }
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    },
    alert() {
      return this.$store.state.alert
    },
    changePaymentCardDialog() {
      return this.$store.state.stripe.changePaymentCardDialog
    },
  },
  methods: {
    async checkConnexion() {
      if (this.$store.state.account.status.loggedIn) {
        // await requestService.get('/stats/check-connexion')
        await this.$store.dispatch('account/refreshToken')
      }

      this.checkConnexionTimeout = setTimeout(this.checkConnexion, 1800000) // every 30 minutes
    },
    channelCreated() {
      this.createChannelKey++
      this.$root.$emit('channelCreated')
    },
    postCreated() {
      this.createPostKey++
    },
    confirmValid() {
      this.confirmDialog = false
      if (typeof this.confirmFunction === 'function') {
        this.confirmFunction()
      }
    },
    confirmCancel() {
      this.confirmDialog = false
      if (typeof this.confirmCancelFunction === 'function') {
        this.confirmCancelFunction()
      }
    },
    submitReport() {
      this.error = null
      this.formValidation(this.reportForm, true)
      if (this.errorsNbr > 0) {
        return
      }
      this.submitLoading = true
      const data = {
        description: this.reportForm.reportMessage.value,
        post: this.reportedPost.uid,
      }
      if (this.reportedPost.uid === undefined) {
        delete data.post
        data.chatMessageId = this.reportedPost.id
      }
      requestService
        .post('/post/report', data)
        .then(response => {
          if (response.success) {
            this.$store.dispatch('alert/success', this.$tc('post.report-send'))
            this.reportDialog = false
            this.reportKey += 1
          }
        })
        .catch(response => {
          const { error, validationFailed } = response
          if (validationFailed) {
            this.handleServerErrors(error, this.reportForm)
          } else if (error.detail) {
            this.error = error.detail
          }
        })
        .finally(() => (this.submitLoading = false))
    },
    fetchRemoteConfig() {
      const remoteConfig = getRemoteConfig(firebaseApp)
      remoteConfig.settings.minimumFetchIntervalMillis = 3600000 // 1 hour
      remoteConfig.defaultConfig = {
        globalErrorMessage: '',
      }

      fetchAndActivate(remoteConfig)
        .then(() => {
          const globalErrorMessage = getValue(
            remoteConfig,
            'globalErrorMessage',
          ).asString()
          this.$store.commit('setGlobalErrorMessage', globalErrorMessage)
        })
        .catch(error => {
          console.error(error)
        })
    },
    confirmCardChange() {
      this.$store.dispatch('stripe/setChangePaymentCardDialog', false)
      const elt = document.getElementById('change-card-btn')
      if (elt) {
        elt.click()
      }
    },
    checkModernBrowser() {
      const storedValue = localStorage.getItem('isModernBrowser')
      if (storedValue !== null) {
        this.$store.state.isModernBrowser = storedValue === 'true'
      }
      const browser = bowser.getParser(window.navigator.userAgent)
      const isIPhone =
        browser.getPlatform().type === 'mobile' && browser.getOSName() === 'iOS'
      const iOSVersion = isIPhone ? parseInt(browser.getOSVersion()) : null
      const isModernBrowser =
        (browser.satisfies({
          chrome: '>94',
          firefox: '>94',
          safari: '>16',
          edge: '>94',
          opera: '>94',
        }) &&
          (!isIPhone || iOSVersion >= 17)) ||
        this.isSamsungInternetModern(browser)
      if (isModernBrowser !== this.$store.state.isModernBrowser) {
        this.$store.state.isModernBrowser = isModernBrowser
        localStorage.setItem('isModernBrowser', isModernBrowser)
      }
    },
    isSamsungInternetModern(browser) {
      const browserName = browser.getBrowserName()?.toLowerCase()
      if (!browserName.includes('samsung internet')) {
        return false
      }

      const version = parseInt(browser.getBrowserVersion(), 10)
      return version >= 10
    },
  },
  mounted() {
    this.checkConnexion()
    this.$root.$on('reload', () => {
      this.random++
    })
    this.$root.$on('createChannel', () => {
      this.createChannelDialog = true
    })
    this.$root.$on('createPost', () => {
      this.createPostDialog = true
    })
    this.$root.$on('createOrganization', () => {
      this.createOrganizationDialog = true
    })
    this.$root.$on('report', post => {
      this.reportedPost = post
      this.reportDialog = true
    })
    this.$root.$on('confirm', (message, onConfirm, onCancel) => {
      this.confirmMessage = message || this.$tc('label.confirm-action') + ' ?'
      this.confirmFunction = onConfirm
      this.confirmCancelFunction = onCancel
      this.confirmDialog = true
    })
    this.fetchRemoteConfig()
  },
  // watch: {
  //   '$store.state.donationDialog'() {
  //     if (this.$store.state.donationDialog) {
  //       this.donationDialog = true
  //     }
  //     return true
  //   },
  // },
  created() {
    this.checkModernBrowser()
    // this.unwatchUser = this.$store.watch(
    //   state => {
    //     return state.account.status.loggedIn
    //   },
    //   newValue => {
    //     if (!newValue) {
    //       this.$store.dispatch('account/logout')
    //     }
    //   },
    // )
  },
  beforeDestroy() {
    // this.unwatchUser()
    clearTimeout(this.checkConnexionTimeout)
  },
})
</script>

<style lang="scss">
@import '~plyr/dist/plyr.css';
//@import 'src/sass/variables';
//$material-dark: (
//  'background': #fff,
//  'background-color': #fff,
//);
.theme--dark.v-list-item .v-list-item__subtitle,
.theme--dark.v-list-item .v-list-item__action-text {
  color: white;
}

.reported-chat-message {
  margin: 10px;
  padding: 5px;
  border: 1px solid;
  border-color: var(--v-secondary-lighten1);
  border-radius: 5px;
}

/* Works on Firefox */
body,
html {
  scrollbar-width: thin;
  scrollbar-color: var(--v-secondary-lighten2) #121212;
}

/* Works on Chrome, Edge, and Safari */
body::-webkit-scrollbar,
html::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track,
html::-webkit-scrollbar-track {
  background: #121212;
}

body::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb {
  background-color: var(--v-secondary-lighten2);
  border-radius: 20px;
  border: 3px solid #121212;
}

/* Works on Firefox */
div {
  scrollbar-width: thin;
  scrollbar-color: var(--v-secondary-lighten2) var(--v-secondary-base);
}

/* Works on Chrome, Edge, and Safari */
div::-webkit-scrollbar {
  width: 12px;
}

div::-webkit-scrollbar-track {
  background: var(--v-background-base);
}

div::-webkit-scrollbar-thumb {
  background-color: var(--v-secondary-lighten2);
  border-radius: 20px;
  border: 3px solid var(--v-background-base);
}

.btn-vertical {
  height: 45px !important;
  &.v-btn--round {
    width: 45px !important;
  }
  .v-btn__content {
    flex-direction: column;
    padding-bottom: 2px;
    .text-sm-overline {
      line-height: 1.2rem;
    }
  }
}

.max-with-viewport {
  max-width: 100vw;
}
</style>
